<template>
  <section>
    <div
      id="invite_creator"
      class="modal fade text-start modal-primary"
      tabindex="-1"
      aria-labelledby="myModalLabel160"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="myModalLabel160"
              class="modal-title"
            >
              Primary Modal
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            Tart lemon drops macaroon oat cake chocolate toffee chocolate bar
            icing. Pudding jelly beans carrot cake pastry gummies cheesecake
            lollipop. I love cookie lollipop cake I love sweet gummi bears
            cupcake dessert.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SendInvite',
}
</script>

<style scoped></style>
