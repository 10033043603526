<template>
  <section>
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          Creators
        </h4>
        <button
          type="button"
          class="btn btn-primary"
          @click="loadSendInvitemodal()"
        >
          Invite Creators
        </button>
      </div>
      <div class="card-body">
        <Table
          :fields="fields"
          :api-u-r-l="url"
          :title="'Creators'"
        />
      </div>
    </div>
    <InviteCreator />
    <!--    <SendInviteModal />-->
  </section>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
// import { BButton } from 'bootstrap-vue'
// import * as $ from 'jquery'
// import $ from 'jquery';
// import jquery from 'jQuery'
import Table from '@/components/Table/GenericTable.vue'
// import SendInviteModal from '@/components/Invites/SendInviteModal.vue'
import InviteCreator from '@/components/Invites/SendInvite.vue'

export default {
  components: {
    // BCardCode,
    Table,
    // BButton,
    // SendInviteModal,
    InviteCreator,
  },
  data() {
    return {
      url: '/users/get_all_creators',
      fields: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'fullName',
          label: 'Full Name',
          sortable: true,
        },
        {
          key: 'contactNo',
          label: 'Contact No',
        },
        {
          key: 'active',
          label: 'Active',
          formatter: value => (value ? 'Yes' : 'No'),
        },
      ],
    }
  },
  methods: {
    loadSendInvitemodal() {
      // window.$ = jquery
      // $('#invite_creator').modal('show')
    },
  },
}
</script>

<style></style>
