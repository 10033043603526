<template>
  <section>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-table
            id="creators_table"
            striped
            hover
            responsive
            :per-page="0"
            :current-page="currentPage"
            :items="allRows"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :fields="fields"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="totalRowsCount"
            align="center"
            size="sm"
            class="my-0"
            aria-controls="creators_table"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </section>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BSpinner,
  BCardBody,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
// import BCardCode from '../../@core/components/b-card-code/BCardCode'

export default {
  name: 'GenericTable',
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BSpinner,
    BCardBody,
  },
  props: {
    apiURL: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pageSize: 2,
      totalRowsCount: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      allRows: [],
      filter: null,
      filterOn: [],
      isBusy: true,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    currentPage() {
      this.toggleBusy()
      this.fetchAllData()
    },
  },
  mounted() {
    this.fetchAllData()
  },
  methods: {
    async fetchAllData() {
      const pagination = {
        page: this.currentPage - 1,
        pageSize: this.pageSize,
      }
      const resp = await this.$http.post(
        this.$store.state.app.mainAppURL + this.apiURL,
        pagination,
      )
      this.allRows = resp.data.content

      this.totalRowsCount = resp.data.totalElements
      this.toggleBusy()
    },
    toggleBusy() {
      this.isBusy = !this.isBusy
    },
  },
}
</script>

<style scoped></style>
